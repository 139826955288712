import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDeliveryGoodsOrderFunc, updateDeliveryGoodsOrderFunc } from "../api";
import {formatDateToYYYYMMDDHHMM} from "../utils";
const PrintReceiving = React.forwardRef((props, ref) => {
    const [data, setData] = useState([]);
    const { id } = useParams();
    const [state, setState] = useState("")

    const [preview, setPreview] = useState("")
    const [imgSrc, setImgSrc] = useState("");
    const [installPreview, setInstallPreview] = useState("");

    const [shippingBox, setShippingBox] = useState({ boxLarge: "", boxMedium: "", boxSmall: "", boxTotal: "" })
    const [quickService, setQuickService] = useState({ damas: "", motorcycle: "", quickTotal: "" })
    const [parcel, setParcel] = useState({ parcelLarge: "", parcelMedium: "", parcelSmall: "", parcelTotal: "" })

    const [forms, setForms] = useState({
        orderCode: "",
        deliveryMethod: "",
        staffPhone: "",
        recipientName: "",
        recipientPhone: "",
        addr1: "",
        addr2: "",
        zipCode: "",
        specialMemo: "",
        state: "신규",
        addressID: "",
        totalPrice: 0,
        shippingDate: ""

    });
    useEffect(() => {
        //  전체 물품 리스트
        const DeliveryGoodsOrderGet = async (id) => {
            const response = await getDeliveryGoodsOrderFunc(id);
            if (response.status === 200) {

                setData(response.data)
                // console.log("🚀 ~ DeliveryGoodsOrderGet ~ response.data:", response.data)
                setForms({
                    ...forms,
                    orderCode: response?.data?.orderCode || "",
                    deliveryMethod: response?.data?.combined ? "합배송" : "개별배송" || "",
                    staffPhone: response?.data?.staffPhone,
                    recipientName: response?.data?.recipientName,
                    recipientPhone: response?.data?.recipientPhone,
                    addr1: response?.data?.addr1,
                    addr2: response?.data?.addr2,
                    zipCode: response?.data?.zipCode,
                    specialMemo: response?.data?.specialMemo,
                    state: response?.data?.state,
                    shippingDate: response?.data?.shippingDate,
                    totalPrice: response?.data?.totalPrice,
                    addressID: response?.data?.addressID || "",
                })
                //     setPreview(response.data.eventImage)
                setState(response?.data?.state)
                if (response?.data) {
                    if (Number(response?.data?.totalPrice) !== 0) {
                        console.log(response);
                        setParcel(JSON.parse(response.data?.parcel))
                        setShippingBox(JSON.parse(response.data?.shippingBox))
                        setQuickService(JSON.parse(response.data?.quickService))
                    }
                }

            }


        };
        DeliveryGoodsOrderGet(id)


    }, [id]);
    return (
        <div ref={ref}>
            <div key={1} className={`print_wapper print_wapper_order`}>
                <div className={`paper ${data?.goods?.length === 5 ? "paper_padding" : ""}`}>
                    <div className="content">
                        <div className="header">
                            <h1>배송 신청서</h1>
                        </div>
                        <div className="row" style={{gridTemplateColumns: "repeat(4, minmax(0,1fr) max-content)",maxWidth:"102%",boxSizing:"border-box"}}>
                            <p className="title">신청번호</p>
                            <p className="half">{data?.orderCode || ""}        </p>
                            <p className="title">신청일자</p>
                            <p className="half">{data?.createdAt ? formatDateToYYYYMMDDHHMM(data?.createdAt) : ""}</p>
                            <hr/>
                            <p className="title">회사명</p>
                            <p className="half">{data.companyName}</p>
                            <p className="title">부서명</p>
                            <p>{data.department}</p>
                            <hr/>
                            <p className="title">신청자</p>
                            <p className="half">{data?.staffName || ""}</p>
                            <p className="title">전화번호</p>
                            <p>{data?.staffPhone || ""}</p>
                            <hr/>
                            <p className="title">
                                수취자
                            </p>
                            <p className="half" style={{wordBreak: "break-all"}}>{data?.recipientName || ""}</p>
                            <p className="title">수취자 연락처</p>
                            <p>{data?.recipientPhone || ""}</p>
                            <hr/>
                            <p className="title">수취자 주소지</p>
                            <p className="full">{`${data?.addr1}${data?.addr2}` || ""}</p>
                            <hr/>
                            <p className="title">비고</p>
                            <p className="full"></p>
                            <hr/>
                            <p className="title">기타 요청 사항</p>
                            <p className="full">{data?.specialMemo || "특이사항 없음"}</p>
                            <hr/>
                            <p className="title">택배사 박스</p>
                            <ul>
                                <li>
                                    <p>대</p>
                                    <p className="countBox">
                                        {"개"}
                                    </p>
                                </li>
                                <li>
                                    <p>중</p>
                                    <p className="countBox" style={{paddingLeft: "10px"}}>
                                        {"개"}
                                    </p>
                                </li>
                                <li>
                                    <p>소</p>
                                    <p className="countBox">
                                        개
                                    </p>
                                </li>
                                <li>
                                    <p>극소
                                    </p><p className="countBox">
                                    개
                                </p>
                                </li>
                            </ul>
                            <hr/>
                            <p className="title">DTT 박스</p>
                            <ul>
                                <li>
                                    <p>대</p>
                                    <p className="countBox">
                                        개
                                    </p>
                                </li>
                                <li>
                                    <p>중</p>
                                    <p className="countBox">
                                        개
                                    </p>
                                </li>
                                <li>
                                    <p>소</p>
                                    <p className="countBox">
                                        개
                                    </p>
                                </li>
                            </ul>
                            
                        </div>

                        <div className="table">
                            <p className="title">순번</p>
                            <p className="title">제품명</p>
                            <p className="title">분류</p>
                            <p className="title">행사물품</p>
                            <p className="title">이미지</p>
                            <p className="title">주문수량</p>
                            <p className="title">단위</p>
                            <p className="title">적재위치</p>
                            <p className="title">비고</p>
                            {data?.goods?.map((it, idx) => (
                                <>
                                    <p>{data?.goods?.length - idx}</p>
                                    <p>{it.goodsId?.productName}</p>
                                    <p>{it.goodsId?.category}</p>
                                    <p>{it?.goodsId?.goodsName}</p>
                                    <p><img
                                        src={it?.goodsId?.goodsImage[0]}
                                        alt=''
                                    /></p>
                                    <p>{it?.amount} </p>
                                    <p>{it?.goodsId?.unit}</p>
                                    <p>{it?.goodsId?.loadingLocation}</p>
                                    <p></p>
                                </>

                            ))}


                        </div>
                    </div>

                </div>

            </div>
            {/*<div className="print_wapper">*/}
            {/*    <div className="print-form">*/}
            {/*        <h1 className="title">배송 신청서</h1>*/}
            {/*        <div className="print-info">*/}
            {/*            <p className="title">신청번호</p>*/}
            {/*            <p className="half">{data?.orderCode || ""}</p>*/}
            {/*            <p className="title">신청일자</p>*/}
            {/*            <p className="half">{new Date(data?.createdAt)*/}
            {/*                .toLocaleString()*/}
            {/*                .slice(0, 13) || ""}</p>*/}
            {/*            <hr/>*/}
            {/*            <p className="title">회사명</p>*/}
            {/*            <p className="half">{data?.companyName || ""}</p>*/}
            {/*            <p className="title">부서명</p>*/}
            {/*            <p>{data?.department || ""}</p>*/}
            {/*            <hr/>*/}
            {/*            <p className="title">신청자</p>*/}
            {/*            <p className="half">{data?.staffName || ""}</p>*/}
            {/*            <p className="title">전화번호</p>*/}
            {/*            <p>{data?.staffPhone || ""}</p>*/}
            {/*            <hr/>*/}
            {/*            <p className="title">*/}
            {/*                수취자*/}
            {/*            </p>*/}
            {/*            <p className="half">{data?.recipientName || ""}</p>*/}
            {/*            <p className="title">수취자 연락처</p>*/}
            {/*            <p>{data?.recipientPhone || ""}</p>*/}
            {/*            <hr/>*/}
            {/*            <div className="full">*/}
            {/*                <p className="title">수취자 주소지</p>*/}
            {/*                <p>{`${data?.addr1}${data?.addr2}` || ""}</p>*/}
            {/*                <hr/>*/}
            {/*                <p className="title">비고</p>*/}
            {/*                <p></p>*/}
            {/*                <hr/>*/}
            {/*                <p className="title">기타 요청 사항</p>*/}
            {/*                <p>{data?.specialMemo || "특이사항 없음"}</p>*/}
            {/*                <hr/>*/}
            {/*                <p className="title">DTT 박스 크기</p>*/}
            {/*                <ul>*/}
            {/*                    <li>*/}
            {/*                        <p>대</p>*/}
            {/*                        <p className="countBox">*/}
            {/*                            ________개*/}
            {/*                        </p>*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        <p>중</p>*/}
            {/*                        <p className="countBox">*/}
            {/*                            ________개*/}
            {/*                        </p>*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        <p>소</p>*/}
            {/*                        <p className="countBox">*/}
            {/*                            ________개*/}
            {/*                        </p>*/}
            {/*                    </li>*/}
            {/*                </ul>*/}

            {/*                <hr/>*/}
            {/*                <p className="title">택배사 박스 크기</p>*/}
            {/*                <ul>*/}
            {/*                    <li>*/}
            {/*                        <p>대</p>*/}
            {/*                        <p className="countBox">*/}
            {/*                            ________개*/}
            {/*                        </p>*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        <p>중</p>*/}
            {/*                        <p className="countBox">*/}
            {/*                            ________개*/}
            {/*                        </p>*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        <p>소</p>*/}
            {/*                        <p className="countBox">*/}
            {/*                            ________개*/}
            {/*                        </p>*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        <p>극소*/}
            {/*                        </p><p className="countBox">*/}
            {/*                        ________개*/}
            {/*                    </p>*/}
            {/*                    </li>*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <table className="print-detail">*/}
            {/*            <thead>*/}
            {/*            <tr>*/}
            {/*                <th>순번</th>*/}
            {/*                <th>제품명</th>*/}
            {/*                <th>분류</th>*/}
            {/*                <th>행사물품</th>*/}
            {/*                <th>이미지</th>*/}
            {/*                <th>주문수량</th>*/}
            {/*                <th>단위</th>*/}
            {/*                <th>적재위치</th>*/}
            {/*                <th>비고</th>*/}
            {/*            </tr>*/}
            {/*            </thead>*/}
            {/*            <tbody>*/}
            {/*            {data?.goods?.map((it, idx) => (*/}
            {/*                <tr key={idx}>*/}
            {/*                    <td>*/}
            {/*                        <p>{data?.goods?.length - idx}</p>*/}
            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                        <p>{it.goodsId?.productName}</p>*/}
            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                        <p>{it.goodsId?.category}</p>*/}
            {/*                    </td>*/}
            {/*                    <td><p>{it?.goodsId?.goodsName}</p></td>*/}
            {/*                    <td>*/}
            {/*                        <p>*/}
            {/*                        <span className='pImg'>*/}
            {/*                            <img*/}
            {/*                                src='/src/assets/images/sub/apc01.jpg'*/}
            {/*                                alt=''*/}
            {/*                            />*/}
            {/*                        </span>*/}
            {/*                        </p>*/}
            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                        <p>*/}
            {/*                            {it?.amount}*/}
            {/*                        </p>{" "}*/}
            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                        <p>{it?.goodsId?.unit}</p>*/}
            {/*                    </td>*/}
            {/*                    <td></td>*/}
            {/*                    <td></td>*/}
            {/*                </tr>*/}
            {/*            ))}*/}
            {/*            </tbody>*/}
            {/*        </table>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </div>

    );
});

export default PrintReceiving;

import React, { memo } from 'react'
import { Button, Col, Pagination, Row, Table } from 'react-bootstrap'

const DepartmentTable = ({ selectFilterStaff, id, navigate }) => {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr className='text-center'>
            <th>번호</th>
            <th>구분</th>
            <th>담당자명/직급</th>
            <th>휴대전화</th>
            <th>영업지역</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {/* TODO:  */}
          {selectFilterStaff &&
            selectFilterStaff.map((it, idx) => (
              <tr
                key={it._id}
                className='text-center '
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={() => navigate("/memberView/" + it._id)}
              >
                {/* <td colSpan={6} style={{ height: "300px" }}>
                  {"No Data"}
                </td> */}
                <td  >{idx + 1}</td>
                <td >{it.position}</td>
                <td >
                  <span style={it?.products?.length ? { backgroundColor: '#0d6efd26' } : null}>
                    {it.staffName}/{it.officerPosition}
                  </span>
                </td>
                <td >{it.phone}</td>
                <td >{it.salesArea}</td>
                <td >{it.isWork ? "근무" : (<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-300 text-slate-600">
                  퇴사
                </span>)}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Row className=' mt-5'>
        <Col className='text-end '>
          <Button
            type='button'
            variant='outline-secondary'
            className='px-4 mx-3'
            onClick={() => {
              navigate("/partnerstable");
            }}
          >
            목 록
          </Button>{" "}
          <Button
            type='button'
            variant='primary'
            className='px-4 mx-3'
            onClick={() => {
              navigate("/memberRegister/" + id);
            }}
          >
            팀원추가
          </Button>{" "}

        </Col>
      </Row>

    </>
  )
}

export default memo(DepartmentTable)